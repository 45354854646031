import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A `}<strong parentName="p">{`Spirit Core`}</strong>{` is the physical embodiment of a `}<a parentName="p" {...{
        "href": "/Spirit%20Manifestation",
        "title": "Spirit Manifestation"
      }}>{`Spirit Manifestation's`}</a>{` `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{`, often taking the form of a crystalline structure near the center of the Spirit's avatar. In order to dispel a manifestation, the Core must be physically destroyed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      